import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';

interface State {
  [key: string]: any;
}

export const get = (property: string) => (state: State) => state[property];
export const set = (property: string) => (state: State, payload: any) => (state[property] = payload);
export const push = (property: string) => (state: State, payload: any) => state[property].push(payload);
export const toggle = (property: string) => (state: State) => (state[property] = !state[property]);

export const findByKey = (property: string, key: string) => (state: State) => {
  return (value: string) => find(state[property], (o) => o[key] === value);
};

export const filterByKey = (property: string, key: string) => (state: State) => {
  return (value: string) => filter(state[property], (o) => o[key] === value);
};

export const filterByKeyInArray = (property: string, key: string) => (state: State) => {
  return (value: any[]) => filter(state[property], (o) => value.includes(o[key]));
};

export const filterByArrayContains = (property: string, key: string) => (state: State) => {
  return (value: string) => filter(state[property], (o) => o[key] instanceof Array && o[key].includes(value));
};

export const filterByQuery = (property: string) => (state: State) => {
  return (value: string) => filter(state[property], (o) => o.matchesQuery(value));
};

export const mapReduce = (property: string, key: string, reducer: (values: any[]) => any) => (state: State) => {
  return reducer(map(state[property], (o) => o[key]));
};
