import { Module, ActionContext } from 'vuex';

import map from 'lodash/map';
import filter from 'lodash/filter';

import { get, set } from '@/utils/vuex';
import { IQueryParams, IPayloadItemCreate, IItem, ItemModel } from '@/models';

export interface IItemsState {
  item: ItemModel | null;
  items: ItemModel[];
}

const initialState: IItemsState = {
  item: null,
  items: [],
};

const getters = {
  item: get('item'),
  items: get('items'),
};

const mutations = {
  setItem: set('item'),
  setItems: set('items'),
};

const actions = {
  async listItems({ commit }: ActionContext<IItemsState, IItemsState>, params?: IQueryParams) {
    const items = await ItemModel.list(params);
    commit('setItems', items);
    return items;
  },

  async getItem({ commit }: ActionContext<IItemsState, IItemsState>, id: string) {
    const item = await ItemModel.get(id);
    commit('setItem', item);
    return item;
  },

  async createItem({ commit, state }: ActionContext<IItemsState, IItemsState>, data: IPayloadItemCreate) {
    const item = await ItemModel.create(data);
    commit('setItems', [...state.items, item]);
    return item;
  },

  async updateItem(
    { commit, state }: ActionContext<IItemsState, IItemsState>,
    { item, data }: { item: ItemModel; data: IItem }
  ) {
    await item.update(data);
    commit(
      'setItems',
      map(state.items, (u) => (u.id === item.id ? item : u))
    );
    return item;
  },

  async deleteItem({ commit, state }: ActionContext<IItemsState, IItemsState>, item: ItemModel) {
    await item.delete();
    commit(
      'setItems',
      filter(state.items, (u) => u.id !== item.id)
    );
  },

  async vote({ state, commit }: ActionContext<IItemsState, IItemsState>, item: ItemModel) {
    const updatedItem = await item.vote();
    const items = map(state.items, (i: ItemModel) => (i.id === item.id ? updatedItem : i));
    commit('setItems', items);
    return updatedItem;
  },
};

const module: Module<IItemsState, IItemsState> = {
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
