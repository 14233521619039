import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<App>({
  metaInfo(): MetaInfo {
    return {
      titleTemplate: '%s | Human Rights News',
    };
  },
})
class App extends Vue {}

export default App;
