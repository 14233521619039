import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { MetaInfo } from 'vue-meta';

import { VuetifyForm } from '@/plugins/vuetify';
import rules from '@/utils/validation';
import { IPayloadItemCreate, ItemModel } from '@/models';

@Component<Submit>({
  metaInfo(): MetaInfo {
    return {
      title: 'Submit',
    };
  },
})
class Submit extends Vue {
  protected readonly rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  protected isLoading = false;
  protected data: IPayloadItemCreate = ItemModel.getInitialData();

  @Getter
  protected isAuthenticated!: boolean;

  @Mutation
  protected setNotification!: (msg: string) => void;

  @Action
  protected createItem!: (payload: IPayloadItemCreate) => void;

  @Watch('isAuthenticated', { immediate: true })
  protected onIsAuthenticated() {
    if (!this.isAuthenticated) {
      this.setNotification('You must be logged in to submit links.');
      this.$router.push({ name: 'login' });
    }
  }

  protected async doSubmit() {
    if (this.form.validate()) {
      this.isLoading = true;

      try {
        await this.createItem(this.data);
      } catch (e) {
        this.isLoading = false;
        if (e.response) {
          const { message } = e.response.data;
          this.setNotification(`Submit failed: ${message}`);
        } else {
          this.setNotification('Submit failed. Please try again later.');
        }
        return;
      }
      this.data = ItemModel.getInitialData();
      this.isLoading = false;
      this.setNotification('Your link has been submitted');
      this.$router.push({ name: 'home' });
    }
  }
}

export default Submit;
