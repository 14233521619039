import '@babel/polyfill';
import Vue from 'vue';

import '@/main.scss';

import { vuetify } from '@/plugins';
import '@/components';
import store from '@/store';
import App from './App.vue';
import router from '@/router';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
