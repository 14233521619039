import axios, { AxiosResponse } from 'axios';

import { camelizeKeys, snakeKeys } from '@/utils/literals';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.defaults.headers.common = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

axios.interceptors.request.use(config => {
  if (config.data) {
    config.data = snakeKeys(config.data);
  }
  return config;
});

axios.interceptors.response.use((value: AxiosResponse<any>) => {
  value.data = camelizeKeys(value.data);
  return value;
});

export default axios;
