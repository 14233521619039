import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { ItemModel } from '@/models';

@Component
class Item extends Vue {
  @Prop({ type: Object, required: true })
  protected item!: ItemModel;

  @Prop({ type: Number, required: true })
  protected index!: number;

  @Getter
  protected isAuthenticated!: boolean;

  @Action
  protected vote!: (item: ItemModel) => void;

  @Mutation
  protected setNotification!: (msg: string) => void;

  protected onVoteClicked() {
    if (!this.item.voted) {
      if (!this.isAuthenticated) {
        this.setNotification('You must be logged in to upvote.');
        return;
      }

      this.vote(this.item);
    }
  }

  protected get points() {
    return `${this.item.points} point` + (this.item.points !== 1 ? 's' : '');
  }
}

export default Item;
