export const isRequired = (value: any) => {
  if (value instanceof Array) {
    return value.length > 0 || 'This field is required';
  }
  return !!value || 'This field is required';
};

export const isMinLength = (minLength: number) => (value: string) =>
  value.trim().length >= minLength || `must be at least ${minLength} characters`;

export const isMaxLength = (maxLength: number) => (value: string) =>
  value.trim().length <= maxLength || `max. ${maxLength} characters allow`;

export const isGreaterThan = (minValue: number) => (value: number) =>
  value > minValue || 'This must be greater than ' + minValue;

export const isGreaterOrEqualThan = (minValue: number) => (value: number) =>
  value >= minValue || 'This must be greater of equal ' + minValue;

const reUsername = /^[A-Za-z0-9]{3,15}$/;
export const isUsername = (value: string) =>
  reUsername.test(value) || 'A username must be between 3 and 15 characters and can only contain letters and numbers';

const reEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,32})+$/;
export const isEmail = (value: string) => reEmail.test(value) || 'Not a valid e-mail';

// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
export const isValidPassword = (value: string) =>
  (value.length >= 8 && /\d/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value)) ||
  'Password must be at least 8 characters long and include one uppercase and one number';

export const isPasswordEqual = (password: string) => (value: string) => value === password || 'Passwords are not equal';

export default {
  required: isRequired,
  minLength: isMinLength,
  maxLength: isMaxLength,
  greaterThan: isGreaterThan,
  greaterOrEqualThan: isGreaterOrEqualThan,
  username: isUsername,
  email: isEmail,
  password: isValidPassword,
  passwordEqual: isPasswordEqual,
};
