import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Action, Getter } from 'vuex-class';
import { UserModel } from '@/models';
import { MeUser } from '@/store/auth';

@Component<User>({
  metaInfo(): MetaInfo {
    const isMe = this.$route.params.username === 'me';
    const title = this.user ? `${this.user.username}'s profile` : undefined;
    return {
      title,
      meta: isMe ? [{ name: 'robots', content: 'noindex,follow' }] : undefined,
    };
  },
})
class User extends Vue {
  protected isLoading = true;

  @Getter
  protected me!: MeUser;

  @Getter
  protected user!: UserModel;

  @Action
  protected getUser!: (username: string) => void;

  @Watch('$route', { immediate: true })
  protected async onRouteChanged() {
    const { username } = this.$route.params;
    if (this.me && username === this.me.sub) {
      this.$router.push({ name: 'user', params: { username: 'me' } });
    }

    this.isLoading = true;
    await this.getUser(username);
    this.isLoading = false;
  }
}

export default User;
