import { RawLocation } from 'vue-router';
import { DateTime } from 'luxon';

import axios from '@/axios';
import BaseModel, { IBaseModel, IQueryParams } from './_base';

export interface IUser extends IBaseModel {
  username: string;
  karma?: number;
}

export class UserModel extends BaseModel<IUser> {
  public static async get(id: string) {
    const resp = await axios.get(UserModel.getAPIEndpoint(id));
    return new UserModel(resp.data);
  }

  public static async update(data: IUser) {
    const resp = await axios.put(UserModel.getAPIEndpoint(), data);
    return new UserModel(resp.data);
  }

  protected static url = '/api/v1/users';

  public async update(data: IUser) {
    const resp = await axios.put(UserModel.getAPIEndpoint(this.id), data);
    this.data = resp.data;
    return this;
  }

  public get username() {
    return this.data.username;
  }

  public get karma() {
    return this.data.karma;
  }

  public get createdAtFormatted() {
    return DateTime.fromISO(this.data.createdAt).toLocaleString(DateTime.DATE_MED);
  }

  public get route(): RawLocation {
    return { name: 'user', params: { username: this.username } };
  }

  public toString() {
    return this.username;
  }
}
