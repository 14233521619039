import { RawLocation } from 'vue-router';
import map from 'lodash/map';

import axios from '@/axios';
import BaseModel, { IBaseModel, IQueryParams } from './_base';

export const ITEM_TYPE_STORY = 'story';

export interface IPayloadItemCreate {
  url: string;
  title: string;
  text: string;
  type: string;
  parent_id?: string;
}

export interface IItem extends IBaseModel, IPayloadItemCreate {
  source: string;
  points: number;
  voted?: boolean;
  username?: string;
}

export class ItemModel extends BaseModel<IItem> {
  public static getInitialData(): IPayloadItemCreate {
    return {
      url: '',
      title: '',
      text: '',
      type: ITEM_TYPE_STORY,
    };
  }

  public static async list(params: IQueryParams = {}) {
    const resp = await axios.get(ItemModel.getAPIEndpoint(null, params));
    return map(resp.data, (data: IItem) => new ItemModel(data));
  }

  public static async get(id: string) {
    const resp = await axios.get(ItemModel.getAPIEndpoint(id));
    return new ItemModel(resp.data);
  }

  public static async create(data: IPayloadItemCreate) {
    const resp = await axios.post(ItemModel.getAPIEndpoint(), data);
    return new ItemModel(resp.data);
  }

  protected static url = '/api/v1/items';

  public async update(data: IItem) {
    const resp = await axios.put(ItemModel.getAPIEndpoint(this.id), data);
    this.data = resp.data;
    return this;
  }

  public async delete() {
    await axios.delete(ItemModel.getAPIEndpoint(this.id));
    return this;
  }

  public async vote() {
    const resp = await axios.post(ItemModel.getAPIEndpoint(this.id, undefined, 'vote'));
    this.data = resp.data;
    return this;
  }
  public get url() {
    return this.data.url;
  }

  public get title() {
    return this.data.title;
  }

  public get text() {
    return this.data.text;
  }

  public get source() {
    return this.data.source;
  }

  public get username() {
    return this.data.username;
  }

  public get points() {
    return this.data.points;
  }

  public get voted() {
    return this.data.voted;
  }

  public get route(): RawLocation {
    return { name: 'item', params: { itemId: this.id } };
  }

  public toString() {
    return this.title;
  }
}
