import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import * as views from '@/views';
import store from '@/store';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: views.Items,
  },
  {
    path: '/:q(newest|show|ask)',
    name: 'home--filter',
    component: views.Items,
  },
  {
    path: '/users/:username',
    name: 'user',
    component: views.User,
  },
  {
    path: '/submit',
    name: 'submit',
    component: views.Submit,
  },
  {
    path: '/login',
    name: 'login',
    component: views.Login,
  },
  {
    path: '/signup',
    name: 'signup',
    component: views.Signup,
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { isPublic: false },
    beforeEnter: () => {
      store.dispatch('logout');
    },
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: views.Imprint,
  },
  {
    path: '/code-of-conduct',
    name: 'code-of-conduct',
    component: views.CodeOfConduct,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to: Route) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
