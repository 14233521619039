import { RawLocation } from 'vue-router';
import { DateTime } from 'luxon';

import router from '@/router';

export interface IBaseModel {
  id: string;
  createdAt: string;
  updatedAt: string;
}

export interface IQueryParams {
  [key: string]: any;
}

abstract class BaseModel<T extends IBaseModel> {
  protected static url: string;

  protected static getAPIEndpoint(id?: string | null, params: IQueryParams = {}, action: string | null = null): string {
    let endpoint = this.url + (id ? '/' + id : '') + (action ? '/' + action : '') + '?';
    for (const [key, value] of Object.entries(params)) {
      endpoint += `${key}=${value}&`;
    }
    return endpoint;
  }

  constructor(protected data: T) {}

  public get rawData() {
    return this.data;
  }

  public get id() {
    return this.data.id;
  }

  public get createdAt() {
    return this.data.createdAt;
  }

  public get updatedAt() {
    return this.data.updatedAt;
  }

  public get createdAtFormatted() {
    return DateTime.fromISO(this.data.createdAt).toLocaleString(DateTime.DATETIME_MED);
  }

  public get updatedAtFormatted() {
    return DateTime.fromISO(this.data.updatedAt).toLocaleString(DateTime.DATETIME_MED);
  }

  public get route(): RawLocation {
    return '';
  }

  public get goto() {
    if (this.route) {
      return () => router.push(this.route);
    } else {
      throw new Error('route() not implemented on model: ' + this.toString());
    }
  }

  public abstract toString(): string | undefined;
}

export default BaseModel;
