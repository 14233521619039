import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Action, Getter, Mutation } from 'vuex-class';

import { VuetifyForm } from '@/plugins/vuetify';
import { LoginPayload } from '@/store/auth';
import rules from '@/utils/validation';

const defaultLoginPayload: LoginPayload = {
  username: '',
  password: '',
};

@Component<Login>({
  metaInfo(): MetaInfo {
    return {
      title: 'Login',
      meta: [{ name: 'robots', content: 'noindex,follow' }],
    };
  },
})
class Login extends Vue {
  protected readonly rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  protected isLoading = false;
  protected credentials: LoginPayload = { ...defaultLoginPayload };

  @Getter
  protected isAuthenticated!: boolean;

  @Mutation
  protected setNotification!: (msg: string) => void;

  @Action
  protected login!: (payload: LoginPayload) => void;

  @Watch('isAuthenticated', { immediate: true })
  protected onAuthenticated() {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'home' });
    }
  }

  protected async doLogin() {
    if (this.form.validate()) {
      this.isLoading = true;
      try {
        await this.login(this.credentials);
      } catch (e) {
        this.isLoading = false;
        this.setNotification('Login failed. Please check your credentials and try again.');
        return;
      }
      this.isLoading = false;
    }
  }
}

export default Login;
