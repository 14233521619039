import { Vue, Component, Watch } from 'vue-property-decorator';
import { Mutation, Getter } from 'vuex-class';

@Component
class Notification extends Vue {
  protected text = '';
  protected timeout = 5000;
  protected visible = false;

  @Getter
  protected notification!: string;

  @Mutation
  protected setNotification!: (text: null | string) => void;

  @Watch('notification')
  protected onNotification() {
    if (this.notification !== null) {
      this.visible = true;
      this.text = this.notification;
      this.setNotification(null);
    }
  }
}

export default Notification;
