import { MeUser } from '@/store/auth';
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component
class Header extends Vue {
  protected isDrawerOpen = false;

  @Getter
  protected me!: MeUser;

  @Action
  protected listItems!: () => void;

  protected async onHome() {
    this.$router.push({ name: 'home' }).catch(() => {});
    this.listItems();
  }
}

export default Header;
