import { Vue, Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Action, Getter } from 'vuex-class';
import { RawLocation } from 'vue-router';

import map from 'lodash/map';
import orderBy from 'lodash/orderBy';

import { ItemModel } from '@/models';

const PAGE_SIZE = 30;

interface Item {
  index: number;
  item: ItemModel;
}

const titles: { [key: string]: MetaInfo } = {
  '': { title: 'Human Rights News', titleTemplate: '' },
  newest: { title: 'Newest' },
  show: { title: 'Show HR' },
  ask: { title: 'Ask HR' },
};

@Component<Items>({
  metaInfo(): MetaInfo {
    const { q } = this.$route.params;
    return titles[q || ''];
  },
})
class Items extends Vue {
  protected items: Item[] = [];
  protected pageIndex = 0;
  protected page = 1;
  protected nextPage: null | RawLocation = null;

  protected isLoading = false;

  @Getter('items')
  protected allItems!: ItemModel[];

  @Action
  protected listItems!: () => void;

  @Watch('$route', { immediate: true })
  protected async onRouteChanged() {
    this.isLoading = true;
    await this.listItems();
    this.isLoading = false;
  }

  protected paging(page = 1): RawLocation {
    return {
      name: this.$route.name || undefined,
      params: { ...this.$route.params },
      query: { ...this.$route.query, page: page.toString() },
    };
  }

  @Watch('allItems', { immediate: true })
  protected computePages() {
    if (this.allItems) {
      this.isLoading = true;
      if (this.$route.query.page) {
        try {
          this.page = parseInt(this.$route.query.page[0] || '1');
        } catch (e) {
          this.$router.push(this.paging());
        }
      } else {
        this.page = 1;
      }

      this.pageIndex = this.page - 1;
      const startIndex = this.pageIndex * PAGE_SIZE + 1;
      let items = this.allItems;
      if (this.$route.params.q === 'newest') {
        items = orderBy(items, ['-createdAt']);
      }
      items = items.slice(this.pageIndex * PAGE_SIZE, this.page * PAGE_SIZE);
      this.items = map(items, (item: ItemModel, index) => ({ index: index + startIndex, item }));

      const totalPages = Math.floor(this.allItems.length / PAGE_SIZE);
      this.nextPage = this.page < totalPages ? this.paging(this.page + 1) : null;
      this.isLoading = false;
    }
  }
}

export default Items;
