import { Store } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

import axios from '@/axios';

const COOKIE_KEY_TOKEN = 'HR_TK';
const COOKIE_KEY_REFRESH = 'HR_RK';

const API_ENDPOINT_LOGIN = '/auth/login';
const API_ENDPOINT_SIGNUP = '/auth/signup';
const API_ENDPOINT_REFRESH = '/auth/refresh';

export interface Token {
  accessToken: string;
  refreshToken: string;
}

export interface LoginPayload {
  username: string;
  password: string;
}

export interface SignupPayload extends LoginPayload {}

export interface MeUser {
  sub: string;
}

export const parseJwt = (token: string): MeUser => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export class JWTAuth {
  protected store?: Store<any>;

  protected token: string | null = null;
  protected refreshToken: string | null;

  public constructor() {
    this.accessToken = Cookies.get(COOKIE_KEY_TOKEN) || null;
    this.refreshToken = Cookies.get(COOKIE_KEY_REFRESH) || null;
    this.registerInterceptors();
  }

  public setStore(store: Store<any>) {
    this.store = store;
    this.refresh();
    this.accessToken = this.token; // re-trigger "setUser"
  }

  public async login(payload: LoginPayload) {
    const resp = await axios.post(API_ENDPOINT_LOGIN, payload);
    return this.authenticate(resp);
  }

  public async signup(payload: SignupPayload) {
    const resp = await axios.post(API_ENDPOINT_SIGNUP, payload);
    return this.authenticate(resp);
  }

  public async refresh() {
    if (this.refreshToken) {
      const resp = await axios.post(API_ENDPOINT_REFRESH, { refreshToken: this.refreshToken });
      return this.authenticate(resp);
    }
    return false;
  }

  public setToken({ accessToken, refreshToken }: Token) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    Cookies.set(COOKIE_KEY_TOKEN, accessToken);
    Cookies.set(COOKIE_KEY_REFRESH, refreshToken);
  }

  public flush() {
    this.accessToken = null;
    this.refreshToken = null;
    Cookies.remove(COOKIE_KEY_TOKEN);
    Cookies.remove(COOKIE_KEY_REFRESH);
    if (this.store) {
      this.store.commit('setMe', null);
    }
  }

  protected set accessToken(value: string | null) {
    const user: MeUser | null = value ? parseJwt(value) : null;
    this.token = value;
    if (this.store) {
      this.store.commit('setMe', user);
    }
  }

  protected get accessToken() {
    return this.token;
  }

  protected registerInterceptors() {
    axios.interceptors.request.use((config) => {
      if (this.accessToken) {
        config.headers.Authorization = 'Bearer ' + this.accessToken;
      }
      return config;
    });

    axios.interceptors.response.use(undefined, (error: AxiosError) => {
      // not an auth-error or no refresh token
      if (!error || !error.response || error.response.status !== 401 || !this.refreshToken) {
        return Promise.reject(error);
      }

      // refreshing token failed
      if (error.config.url === API_ENDPOINT_REFRESH) {
        this.flush();
        return Promise.reject(error);
      }

      // trigger refreshing token
      return this.refresh()
        .then(() => {
          // retry request
          return axios.request(error.config);
        })
        .catch((e) => {
          this.flush();
          return Promise.reject(e);
        });
    });
  }

  protected authenticate(resp: AxiosResponse) {
    if (resp.status === 200) {
      const token: Token = resp.data;
      if (token.accessToken && token.refreshToken) {
        this.setToken(token);
        return true;
      }
    }
    return false;
  }
}
