import { Vue, Component, Prop } from 'vue-property-decorator';
import { DateTime, DurationUnit } from 'luxon';

const UNITS: DurationUnit[] = ['months', 'days', 'hours', 'minutes'];

@Component
class Since extends Vue {
  @Prop({ type: String, required: true })
  protected value!: string;

  @Prop({ type: Number, required: false, default: 10000 })
  protected updateInterval!: number;

  protected interval?: number;

  protected created() {
    this.interval = setInterval(this.$forceUpdate, this.updateInterval);
  }

  protected get dt(): DateTime {
    return DateTime.fromISO(this.value);
  }

  protected get since() {
    const d = this.dt.diffNow().negate();
    for (const unit of UNITS) {
      const value = Math.trunc(d.as(unit));
      if (value > 0) {
        return `${value} ${value > 1 ? unit : unit.slice(0, -1)} ago`;
      }
    }
    return 'just now';
  }

  protected destroyed() {
    clearInterval(this.interval);
  }
}

export default Since;
