import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component<Imprint>({
  metaInfo(): MetaInfo {
    return {
      title: 'Imprint',
      meta: [{ name: 'robots', content: 'noindex,follow' }],
    };
  },
})
class Imprint extends Vue {}

export default Imprint;
