import Vue from 'vue';
import Vuex, { ActionContext, createLogger } from 'vuex';

import { get, set } from '@/utils/vuex';
import { MeUser, LoginPayload, SignupPayload, JWTAuth } from './auth';
import * as modules from './modules';

Vue.use(Vuex);

const auth = new JWTAuth();

export interface RootState {
  me: MeUser | null;
  notification: null | string;
}

const initialState: RootState = {
  me: null,
  notification: null,
};

const getters = {
  isAuthenticated: (state: RootState) => !!state.me,
  me: get('me'),
  notification: get('notification'),
};

const mutations = {
  setMe: set('me'),
  setNotification: set('notification'),
};

const actions = {
  async login({ state, commit }: ActionContext<RootState, RootState>, payload: LoginPayload) {
    await auth.login(payload);
    commit('setNotification', `Welcome! You're now logged in as ${state.me?.sub}`);
  },

  async signup({ state, commit }: ActionContext<RootState, RootState>, payload: SignupPayload) {
    try {
      await auth.signup(payload);
    } catch (e) {
      if (e.response) {
        const { message } = e.response.data;
        commit('setNotification', `Signup failed: ${message}`);
      } else {
        commit('setNotification', 'Signup failed. Please try again later.');
      }
      return;
    }
    commit('setNotification', `Welcome to Human Rights News, ${state.me?.sub}!`);
  },

  logout() {
    auth.flush();
    window.location.href = '/';
  },
};

const plugins = process.env.NODE_ENV === 'development' ? [createLogger()] : [];

const store = new Vuex.Store<RootState>({
  state: initialState,
  getters,
  mutations,
  actions,
  // @ts-ignore
  modules,
  plugins,
});

auth.setStore(store);

export default store;
