import { Module, ActionContext } from 'vuex';

import { get, set } from '@/utils/vuex';
import { IUser, UserModel } from '@/models';

export interface IUsersState {
  user: UserModel | null;
}

const initialState: IUsersState = {
  user: null,
};

const getters = {
  user: get('user'),
};

const mutations = {
  setUser: set('user'),
};

const actions = {
  async getUser({ commit }: ActionContext<IUsersState, IUsersState>, id: string) {
    const user = await UserModel.get(id);
    commit('setUser', user);
    return user;
  },

  async updateUser(
    { commit }: ActionContext<IUsersState, IUsersState>,
    { user, data }: { user: UserModel; data: IUser }
  ) {
    commit('setUser', await user.update(data));
    return user;
  },
};

const module: Module<IUsersState, IUsersState> = {
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
