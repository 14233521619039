import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';

export type VuetifyForm = Vue & {
  reset: () => void;
  resetValidation: () => void;
  validate: () => boolean;
  $el: HTMLFormElement;
};
export interface ISelectItem {
  text: string;
  value: string;
}
export type ISelectItems = ISelectItem[];

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['~/styles/variables.scss'],
  theme: {
    themes: {
      light: {
        primary: colors.indigo.darken2,
        secondary: '#A8DBB8',
      },
    },
  },
});
