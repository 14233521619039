import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

const transformKeys = (transformer: (v: string) => string) => {
  function transform(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map((v) => transform(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [transformer(key)]: transform(obj[key]),
        }),
        {}
      );
    }
    return obj;
  }

  return transform;
};

export const camelizeKeys = transformKeys(camelCase);
export const snakeKeys = transformKeys(snakeCase);
